@import "../../styles/variables";

h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: $kmd-darkgreen;
  text-transform: uppercase;
}

.resultsContainer {
  margin: 0 5vw 50px;
  min-height: calc(100vh - 369px);
}

.progress {
  margin: 1em;
  span {
    display: inline-block;
    margin: 20px 0;
  }
}

.progressBar {
  background-color: $kmd-green-light !important;
  > div {
    background-color: $kmd-green !important;
  }
}

.error {
  margin: 1em;
  font-size: 0.9rem;
  color: red;
  text-align: center;
}
