@import "../../styles/variables";

.header {
  padding: 25px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.logo {
  fill: $kmd-green;
  max-width: 250px;
}
