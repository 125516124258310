@import "../../styles/variables";

.CircularLoader {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  svg {
    color: $kmd-green !important;
  }
  span {
    display: inline-block;
    margin: 25px 0;
  }
}
