@import "../../styles/variables";

.formContainer {
  max-width: 750px;
  margin: 25px auto 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerSwitch {
  display: inline-flex;
  align-items: center;
  padding-top: 10px;
  margin: 0 20px;
}

.button {
  position: relative !important;
  font-family: $font-name !important;
  background-color: $kmd-green !important;
  color: white !important;
  &:hover {
    background-color: $kmd-darkgreen !important;
  }
}

.check {
  color: $kmd-green !important;
}

.line {
  background-color: $kmd-green !important;
}

.smallInfo {
  padding: 5px;
  font-size: .7em;
}