@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
.Results_title__kxA85{font-size:1.5rem;color:#0a3c00;font-weight:bold;margin-bottom:25px}

.Form_formContainer__3urdS{max-width:750px;margin:25px auto 50px;display:flex;justify-content:center;align-items:center}.Form_containerSwitch__2uYv2{display:inline-flex;align-items:center;padding-top:10px;margin:0 20px}.Form_button__2ORia{position:relative !important;font-family:"Montserrat",sans-serif !important;background-color:#9dc641 !important;color:white !important}.Form_button__2ORia:hover{background-color:#0a3c00 !important}.Form_check__2BZmI{color:#9dc641 !important}.Form_line__3Iq7O{background-color:#9dc641 !important}.Form_smallInfo__psaO4{padding:5px;font-size:.7em}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}body{font-family:"Montserrat",sans-serif;border-top:20px solid #0a3c00;border-bottom:20px solid #0a3c00;font-size:16px}.Mui-focused{color:#0a3c00 !important}.Mui-focused:after{border-color:#0a3c00 !important}.MuiInput-underline:after{border-color:#0a3c00 !important}

h1{text-align:center;font-size:2rem;font-weight:bold;color:#0a3c00;text-transform:uppercase}.App_resultsContainer__Ye3vJ{margin:0 5vw 50px;min-height:calc(100vh - 369px)}.App_progress__3LHd6{margin:1em}.App_progress__3LHd6 span{display:inline-block;margin:20px 0}.App_progressBar__3WTKb{background-color:rgba(157,198,65,0.2) !important}.App_progressBar__3WTKb>div{background-color:#9dc641 !important}.App_error__3kwyn{margin:1em;font-size:0.9rem;color:red;text-align:center}

.Header_header__1v0yI{padding:25px;display:flex;justify-content:center;margin-bottom:50px}.Header_logo__3T0lc{fill:#9dc641;max-width:250px}

.CircularLoader_CircularLoader__1UOu1{display:flex;flex-flow:column;justify-content:center;align-items:center}.CircularLoader_CircularLoader__1UOu1 svg{color:#9dc641 !important}.CircularLoader_CircularLoader__1UOu1 span{display:inline-block;margin:25px 0}

