@import "./reset-css";
@import "./variables";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  font-family: $font-name;
  border-top: 20px solid $kmd-darkgreen;
  border-bottom: 20px solid $kmd-darkgreen;
  font-size: 16px;
}

// Overwrite Mateial UI Colors
.Mui-focused {
  color: $kmd-darkgreen !important;
  &:after {
    border-color: $kmd-darkgreen !important;
  }
}

.MuiInput-underline:after {
  border-color: $kmd-darkgreen !important;
}
